<template>
  <Card>
    <template slot="title">
      {{data.qbsAccountName}}
    </template>
    <CardCell :label="data.qbsAccountPhone" />
    <CardCell
      label="上牌城市："
      :value="data.licenseCity"
    />
    <template
      slot="footer"
      v-if="state === 0"
    >
      <span></span>
      <Button
        round
        type="info"
        size="small"
        style="width: 80px;"
        v-auth="'OppSend-Distribution'"
        @click="showPopup(data)"
      >分配</Button>
    </template>
  </Card>
</template>
<script>
import { Button } from 'vant'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
export default {
  data () {
    return {
      show: false,
      add: '123'
    }
  },
  methods: {
    showPopup (cardInfo) {
      //添加自定义属性，传值，判断分配显示内容
      cardInfo.Distribution = '1'
      this.$router.push({
        path: '/taoGuestPoolAllot',
        query: cardInfo
      })
    }
  },
  components: {
    Button,
    Card,
    CardCell
  },
  props: {
    data: {
      type: Object
    }
  },
  inject: ['getState'],
  computed: {
    state () {
      return this.getState()
    }
  }
}
</script>
<style lang='less' scoped>
@import "@/style/common.less";
.card-info {
  display: flex;
  align-items: center;

  .name {
    width: 200px;
    margin-right: 20px;
  }

  .tag {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 8px;
    color: @color-blue;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
    // float: right;
  }
}
</style>