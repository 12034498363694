<template>
  <PullRefresh
    v-model="pullRefresh.loading"
    :disabled="state === 1"
    @refresh="onRefresh"
  >
    <List
      ref="list"
      v-model="list.loading"
      :dataList="dataList"
      :finished="list.finished"
      :error.sync="list.error"
      :showSelection="state === 1"
      :selection="selectionList"
      @on-selection-change="onSelectionChange"
      @load="onLoad"
    >
      <template slot-scope="{ item }">
        <Card :data="item"/>
      </template>
    </List>
  </PullRefresh>
</template>
<script>
import { PullRefresh, Toast } from 'vant'
import List from '_c/list'
import Card from '../card'
export default {
  components: {
    Card,
    List,
    PullRefresh
  },
  inject: ['getState'],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: {
        loading: false,
        finished: true,
        error: false
      },
      pullRefresh: {
        loading: false
      },
      selectionList: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectionList = value
      }
    },
    selectionList(value) {
      this.$emit('input', value)
      // console.log(value)
    }
  },
  computed: {
    state() {
      return this.getState()
    }
  },
  methods: {
    onLoad() {
      this.$emit('on-load')
    },
    onRefresh() {
      this.onLoad()
      setTimeout(() => {
        if(this.$refs.list.loading) {
            this.pullRefresh.loading = false
        }else{
          Toast('刷新失败')
        }
      }, 1000)
      console.log('111111111111')
    },
    onSelectionChange(selection) {
      // console.log(0)
      this.selectionList = selection
      // console.log(selection)
    },
    toggleAll(checked) {
      this.$refs.list.toggleAll(checked)
    }
  }
}
</script>
<style lang="less" scoped>
.van-pull-refresh {
  min-height: 100%;
}
.list {
  padding-top: 16px;

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .checkbox-wrap {
      padding: 0 24px;
    }

    .card-wrap {
      flex: 1;
    }
  }
}
</style>